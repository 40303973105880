import React, { useState, Fragment } from "react";
import { useMatch } from "@reach/router"
import Header from "./Header/Header";
import BannerSection from "./Home/Banner/Banner";
import Getproperty from "./Home/Getproperty/Getproperty";
import AboutSection from "./Home/AboutSection/AboutSection";
import StickyFooter from "./Footer/StickyFooter"
import { InView } from 'react-intersection-observer';
import { LATEST_NEWS_PAGE_URL, MEET_THE_TEAM_PAGE_URL } from "./Common/site/constants";
import _ from "lodash";
import Loadable from "@loadable/component"

const News = Loadable(() => import("./Home/News/News"))
const ServiceSection = Loadable(() => import("./Home/OurServices/OurServices"))
const Footer = Loadable(() => import("./Footer/Footer"))
const Reviews = Loadable(() => import("./Home/Reviews/Reviews"))

const Home = (props) => {
    const [showMenu, setShowMenu] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [bannerInView, setBannerInView] = useState(true);
    const handlerClick = () => {
        setShowMenu(!showMenu)
        var root = document.getElementsByTagName('html')[0];
        if (showMenu == true) {
            document.body.className = '';
            root.className = '';
        } else {
            document.body.classList.add("overflow-hidden");
            root.classList.add("overflow-hidden");
        }
    }

    const handlerSearchClick = () => {
        setShowSearch(!showSearch)
        setShowMenu(false)
        if (showSearch == true) {
            document.body.className = '';
        } else {
            document.body.classList.add("overflow-hidden");
        }
    }


    const NewsDetails = useMatch(LATEST_NEWS_PAGE_URL.alias + "/:item");
    const TeamDetails = useMatch(MEET_THE_TEAM_PAGE_URL.alias + "/:item");
    return (
        <div className={` ${(showMenu || showSearch) ? "wrapper open-search-block" : "wrapper"} ${props.page?.Custom_CSS_Class ? props.page.Custom_CSS_Class : ''}`}>
            <Header showMenu={showMenu} handlerClick={handlerClick} handlerSearchClick={handlerSearchClick} showSearch={showSearch} fixed={true} />
            {props.page && props.page.Layout == "Default" && NewsDetails === null && TeamDetails === null &&
                <InView as="div" onChange={(inView, entry) => setBannerInView(inView)}>
                    <BannerSection page={_.omit(props.page, "Modules")} lazyLoad={props.lazyLoad} handlerClick={handlerClick} handlerSearchClick={handlerSearchClick} showSearch={showSearch} fixed={true} />
                </InView>

            }
            {NewsDetails === null && TeamDetails === null && props.modules && props.modules.map((each, i) =>
                <Fragment key={i}>
                    {(each.Content_with_Image && each.Top_Content !== "") &&
                        <ServiceSection {...each} lazyLoad={props.lazyLoad} />
                    }
                    {(each.Intro_Title && each.Intro_Content) &&
                        <AboutSection {...each} layout={props.page.Layout} />
                    }

                    {each.Reviews_Background_Image &&
                        <Reviews {...each} />
                    }
                    {each.Title_With_Collection == "News" &&
                        <News {...each} />
                    }
                    {each.type == "GLSTRAPI_ComponentModulesFooterBlock" &&
                        <Getproperty {...each} />
                    }
                </Fragment>
            )}
          
            <Footer popularSearch={props.page.Popular_Search} popularSearchType="static" />
            <StickyFooter page={_.omit(props.page, "Modules")} bannerInView={bannerInView} lazyLoad={props.lazyLoad} path={props.path} />
        </div>
    )
}
export default Home
